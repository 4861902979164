@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    font-family: 'Nunito', sans-serif;
}


.bg-primary-50 {
    background-color: #F2F3FF;
}

.bg-primary-100 {
    background-color: #D6DAFF;
}

.bg-primary-200 {
    background-color: #BAC1FF;
}

.bg-primary-300 {
    background-color: #9CA5FB;
}

.bg-primary-400 {
    background-color: #9CA5FB;
}

.bg-primary-500 {
    background-color: #5C68CD;
}

.bg-primary-600 {
    background-color: #434EAB;
}

.bg-primary-700 {
    background-color: #2E3789;
}

.bg-secondary-50 {
    background-color: #F0FEFF;
}

.bg-secondary-300 {
    background-color: #8DF6FC;
}

.bg-secondary-600 {
    background-color: #3BA6AD;
}

.border-primary-100 {
    border-color: #D6DAFF;
}

.border-primary-500 {
    border-color: #5C68CD;
}

.border-primary-700 {
    border-color: #2E3789;
}

.border-secondary-50 {
    border-color: #F0FEFF;
}

.border-secondary-600 {
    border-color: #3BA6AD;
}

.text-primary-400 {
    color: #7A85EF;
}

.text-primary-500 {
    color: #5C68CD;
}

.text-primary-600 {
    color: #434EAB;
}

.text-primary-700 {
    color: #2E3789;
}

.text-secondary-200 {
    color: #AFFAFF;
}

.text-secondary-300 {
    color: #8DF6FC;
}

.text-neutral-300 {
    color: #F0F0F0;
}

.text-neutral-500 {
    color: #737373;
}

.text-neutral-600 {
    color: #404040;
}

.text-neutral-700 {
    color: #202020
}

@layer components {
    .btn-primary {
        @apply bg-primary-700 border-primary-500 border-2 rounded-xl text-white font-bold;
    }

    .btn-secondary {
        @apply border-primary-500 text-primary-500 font-bold rounded-xl border-2
    }

    .modal__backdrop {
        @apply fixed bg-black opacity-25 inset-0
    }

    .nav__menu-item {
        @apply w-screen lg:w-[5.75rem] h-20 lg:h-10 flex items-center justify-center text-center font-bold text-base font-bold text-neutral-400 relative py-8 lg:py-0;
    }
}


::-webkit-scrollbar {
    width: .5rem;
    height: .5rem;
}

::-webkit-scrollbar-track {
    background-color: #F6F6F6;
    border-radius: 99px;
}

::-webkit-scrollbar-thumb {
    border-radius: 99px;
    border: 1px solid #5C68CD;
    background-color: #D6DAFF;
}
